<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="10"
        md="6"
        class="pb-5"
      >
        <loading-circle
          v-if="preLoading"
          class="pa-5"
        />
        <v-card
          v-if="intro"
          flat
          class="pa-3 mb-10"
        >
          <v-card-text
            v-if="intro.image"
          >
            <v-img
              :height="150"
              contain
              position="top left"
              :src="intro.image"
            />
          </v-card-text>
          <v-card-title>
            {{ intro.title }}
          </v-card-title>
          <v-card-text
            v-html="intro.description"
          />
          <v-card-actions>
            <v-btn
              @click="start"
              :loading="postLoading"
              x-large
              color="primary"
              class="ml-2 hidden-xs-only"
            >
              {{ $t('start') }}
              <to-icon right>rocket</to-icon>
            </v-btn>
          </v-card-actions>
          <v-card-actions
            class="hidden-sm-and-up"
          >
            <v-btn
              @click="start"
              :loading="postLoading"
              x-large
              block
              color="primary"
            >
              {{ $t('start') }}
              <to-icon right>rocket</to-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ERROR, GET_PARTICIPANT_ID, INTRO, LOADING } from '@/store/getter-types'
import {
  CHECK_EVALUATION_PARTICIPANT_VALID, LOAD_PERSONALIZED_EVALUATION,
  LOAD_PUBLIC_EVALUATION,
  PRELOAD_PUBLIC_EVALUATION,
  START_PUBLIC_EVALUATION
} from '@/store/action-types'
import { ADD_PARTICIPANT_ID, RESET_ERROR } from '@/store/mutation-types'

import ToIcon from '@/components/to-icon'
import LoadingCircle from '@/components/loading-circle'

@Component({
  components: {
    LoadingCircle,
    ToIcon
  }
})
export default class HomeView extends Vue {
  @Prop({
    type: String,
    required: true
  }) locale

  @Prop({
    type: String,
    required: true
  }) publicEvaluationId

  @Prop({
    type: String,
    required: false
  }) personalizedParticipantId

  @Action(START_PUBLIC_EVALUATION) startPublicEvaluation
  @Action(PRELOAD_PUBLIC_EVALUATION) preloadPublicEvaluation
  @Action(LOAD_PUBLIC_EVALUATION) loadPublicEvaluation
  @Action(LOAD_PERSONALIZED_EVALUATION) loadPersonalizedEvaluation
  @Action(CHECK_EVALUATION_PARTICIPANT_VALID) checkEvaluationParticipantValid
  @Mutation(RESET_ERROR) resetError
  @Getter(GET_PARTICIPANT_ID) getParticipantId
  @Getter(ERROR) error
  @Getter(LOADING) loading
  @Getter(INTRO) intro
  @Mutation(ADD_PARTICIPANT_ID) addParticipantId

  get participantId () {
    return this.getParticipantId(this.publicEvaluationId)
  }

  get preLoading () {
    return this.loading && this.intro === null
  }

  get postLoading () {
    return this.loading && this.intro !== null
  }

  next () {
    this.$router.push({
      name: 'overview',
      params: {
        locale: this.locale,
        publicEvaluationId: this.publicEvaluationId
      }
    })
  }

  async start () {
    if (this.personalizedParticipantId) {
      await this.loadPersonalizedEvaluation({
        publicEvaluationId: this.publicEvaluationId,
        personalizedParticipantId: this.personalizedParticipantId
      })
      this.next()

      return
    }

    if (this.participantId) {
      await this.checkEvaluationParticipantValid(this.publicEvaluationId)
    }

    if (!this.participantId) {
      await this.startPublicEvaluation(this.publicEvaluationId)
    }

    await this.loadPublicEvaluation(this.publicEvaluationId)
    this.next()
  }

  @Watch('error')
  onErrorOccurred (error) {
    if (error !== null) {
      this.$router.push({
        name: 'error',
        params: {
          code: error
        }
      })
    }
  }

  async mounted () {
    this.resetError()
    await this.preloadPublicEvaluation(this.publicEvaluationId)
  }
}
</script>

<i18n>
{
  "de": {
    "start": "Los geht's",
    "error": "Bitte entschuldigen Sie, es ist ein Fehler aufgetreten. Bitte nehmen Sie mit uns Kontakt auf."
  },
  "en": {
    "start": "Let's go",
    "error": "We are sorry, an error has occurred. Please contact us."
  }
}
</i18n>
